import React from 'react'
import PageHeader from '../components/PageHeader'

function Parishad() {
  return (
    <div>
      <PageHeader title="Parishad" />
    </div>
  )
}

export default Parishad
