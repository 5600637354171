import React from 'react'
import PageHeader from '../components/PageHeader'

function Research() {
  return (
    <div>
      <PageHeader title="Researches" />
    </div>
  )
}

export default Research
