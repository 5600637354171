import React from 'react'
import PageHeader from '../components/PageHeader'

function AcademicCalendar() {
  return (
    <div>
      <h1>Academic Calendar</h1>
      <PageHeader title="Academic Calendar" />
    </div>
  )
}

export default AcademicCalendar
