import React from 'react'
import PageHeader from '../components/PageHeader'

function Courses(props) {
  return (
    <div>
      <PageHeader title="Courses" />
    </div>
  )
}

export default Courses
