import React from 'react'
import PageHeader from '../components/PageHeader'

function TeachingPlan() {
  return (
    <div>
      <PageHeader title="Teaching Plan" />
    </div>
  )
}

export default TeachingPlan
