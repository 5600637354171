import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import HeadSection from '../components/HeadSection'

function Faculty(props) {
  return (
    <div>
      <PageHeader title={`Faculty of ${props.stream} `} />
       
      <PageContent elem={<HeadSection/>}/>
    </div>
  )
}

export default Faculty
