import React from 'react'
import PageHeader from '../components/PageHeader'

function Publications() {
  return (
    <div>
      <PageHeader title="Publications" />
    </div>
  )
}

export default Publications
