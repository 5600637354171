import React from 'react'
import PageHeader from '../components/PageHeader'

function ContactUs(props) {
  return (
    <div>
      <PageHeader title={props.title}/>
    </div>
  )
}

export default ContactUs
