import React from 'react'
import PageHeader from '../components/PageHeader'

function UniversityExamination() {
  return (
    <div>
      <PageHeader title="University Examination" />
    </div>
  )
}

export default UniversityExamination
