import React from 'react'
import PageHeader from '../components/PageHeader'

function Workshop() {
  return (
    <div>
      <PageHeader title="Workshop" />
    </div>
  )
}

export default Workshop
