import React from 'react'
import PageHeader from '../components/PageHeader'

function Rules() {
  return (
    <div>
      <PageHeader title="Rules & Regulations" />
    </div>
  )
}

export default Rules
