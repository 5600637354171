import React from 'react';

const EventsSection = ({ events, nextEvents }) => {
  return (
    <div className="table-scholl-sectionps">
      <div className="container">
        <h2>Latest News &amp; <span>Events</span></h2>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-xs-12">
            <div className="row prestige-post-op-p">
              <div className="row col-sm-12 col-xs-12">
                
                {events.map((event, index) => (
                   <div className='col-sm-6'>
                    <div className="event-with-images" key={index}>
                    <a href={event.link}>
                      <div className="img-src" style={{ backgroundImage: `url(${event.image})` }}></div>
                      <div className="menu-jtu0w">
                        <p>Event <span>{event.date}</span></p>
                        <h4>{event.title}</h4>
                      </div>
                    </a>
                  </div>
                   </div> 
                  
                ))}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-xs-12">
            <div className="prestige-post new-uiuhgt">
              <h4>Our Next Event <span>------------</span></h4>
              <marquee behavior="scroll" direction="up" height="312" scrolldelay="100" scrollamount="2">
                <ul className="posts">
                  {nextEvents.map((nextEvent, index) => (
                    <li className="post" key={index}>
                      <h3 className="entry-title">
                        <a href={nextEvent.link}>{nextEvent.title}</a>
                      </h3>
                      <span className="date">
                        <i className="fa fa-calendar" aria-hidden="true"></i> {nextEvent.date}
                      </span>
                      <span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                    </li>
                  ))}
                </ul>
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsSection;
