// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component: Component }) => {
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    alert(isAuthenticated)
    return isAuthenticated ? (
        <Component />
    ) : (
        <Navigate to="/login" />
    );
};

export default ProtectedRoute;
