import React from 'react'

function Login() {
  return (
    <div>
    <h1>Login Js</h1>  
    </div>
  )
}

export default Login
