import React from 'react'
import PageHeader from '../components/PageHeader'

function Honors() {
  return (
    <div>
      <PageHeader title="Honors" />
    </div>
  )
}

export default Honors
