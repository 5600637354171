import React from 'react'
import PageHeader from '../components/PageHeader'

function GuestLecture() {
  return (
    <div>
      <PageHeader title="Guest Lecture" />
    </div>
  )
}

export default GuestLecture
