import React from 'react'
import PageHeader from '../components/PageHeader'

function Timetable() {
  return (
    <div>
      <PageHeader title="Timetable" />
    </div>
  )
}

export default Timetable
