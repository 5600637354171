import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function PrincipalMessage() {
  return (
    <div>
      <PageHeader title="Pricipal Message" />
      <PageContent elem={<Blog image='' content="<p>
प्रो. (डॉ.) सौमित्र चन्द्र

प्राचार्य

मानव जीवन को विकसित करने के लिए शिक्षा ही एक ऐसा माध्यम है जिसके द्वारा देश अपने हजारों साल की संस्कृति को संजोकर एवं भविष्य में दुनिया के विकसित देशो के साथ कदम से कदम मिला कर चल सकेगा। 21वीं शताब्दी में मानव, विज्ञान एवं प्रौद्योगिकी द्वारा ऐसी क्रांति लायेगा जो कल्पना से परे होगा और मानव का दायरा इस संसार तक ही सीमित न रहकर बाह्य जगत से जुड़ेगा।
हमारा संकल्प है कि हम अपने विद्यार्थियों को ऐसी गुणवत्तापरक एवं राजगारोन्मुखी शिक्षा प्रदान करेंगे जो उन्हें आर्थिक रूप से सक्षम एवं स्वावलंबी बनाने के साथ ही साथ एक सच्चे भारतीय नागरिक के गुणों से सुसज्जित हो सके। यह संस्थान अपने उत्तम शैक्षणिक आधारभूत संरचना और सर्वश्रेष्ठ नवोन्मेषी संकायों के द्वारा छात्रों के ज्ञानोपार्जन, अनुशासन व चरित्र निर्माण में सतत संलग्न है। हमारा विश्वास है कि हमारे अध्यापक, छात्र एवं छात्राएं राष्ट्र-निर्माण के महायज्ञ में कदम से कदम मिलाकर आगे बढ़ते रहेंगे और अपने देश को अपने समय में ही एक विकसित देश के रूप में देख सकेंगे।
</p><p>
मेरी शुभकामनाएं !</p>" />}/>
    </div>
  )
}

export default PrincipalMessage
