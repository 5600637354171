import React from 'react'
import PageHeader from '../components/PageHeader'

function IQAC() {
  return (
    <div>
      <PageHeader title="IQAC" />
    </div>
  )
}

export default IQAC
