import React from 'react';

const Blog = ({ image, content }) => {
  // Default image URL
  const defaultImage = "https://vbsgdc.org.in/wp-content/uploads/2020/01/vg1-1024x768.jpeg";

  // Default content
  const defaultContent = (
    <>
      <p><strong>वीर बहादुर सिंह राजकीय महाविद्यालय</strong>, <strong>कम्पियरगंज, गोरखपुर-273158 (उ.प्र.)</strong></p>
      <p><strong>(संबद्ध: दीनदयाल उपाध्याय गोरखपुर विश्वविद्यालय, गोरखपुर)</strong></p>
      <p><strong>महाविद्यालय: एक पुनरावलोकन</strong></p>
      <p>
        उच्च शिक्षा के प्रसार के संकल्प को साकार करने के उद्देश्य से उत्तर प्रदेश सरकार द्वारा प्रदेश के सुदूर एवं पिछड़े क्षेत्रों में राजकीय महाविद्यालयों की स्थापना के क्रम में पनियरा क्षेत्र के स्थानीय विधायक एवं उ.प्र. शासन के तत्कालीन राज्यमंत्री (स्वतंत्र प्रभार), माननीय फतेह बहादुर सिंह के अथक प्रयास से शासनादेश संख्या: शि.मं./29/सत्तर-5-99-40(59)/95 दिनांक 28 अप्रैल, 1999 द्वारा राजकीय महाविद्यालय कम्पियरगंज, गोरखपुर की स्थापना हुई। स्वतंत्रता प्राप्ति के पचास वर्ष बाद भी इस ग्रामीणांचल के छात्र-छात्राओं को उच्च शिक्षा प्राप्त करने के लिए दूर-दराज के महाविद्यालयों में जाना पड़ता था, जिससे अभिभावकों और छात्र-छा़त्राओं को बहुत कठिनाई होती थी। फलतः इस क्षेत्र के विकास हेतु सतत जागरूक माननीय फतेह बहादुर सिंह के अथक परिश्रम से यह राजकीय महाविद्यालय अस्तित्व में आया।
      </p>
    </>
  );

  return (
    <div className="row d-flex justify-content-center align-items-center">
      <div className="col-md-6">
        <img
            style={{width:'100%'}}
                decoding="async"
                
                src={image || defaultImage} // Use prop.image if available, otherwise use defaultImage
                 alt=""
                 />
        
      </div>

      <div className="col-md-6" style={{margin:'auto'}}>
        
              {content!=''?(<div dangerouslySetInnerHTML={{ __html: content }} />):( defaultContent)} {/* Use prop.content if available, otherwise use defaultContent */}
            </div>
         
    </div>
  );
}

export default Blog;
